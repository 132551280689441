import React from "react";
import "./App.css";
import Contact from "./contact";

function App() {
  return (
    <div className="bg-gray-100 h-lvh">
      <div className="text-center md:mt-32 mt-16">
        <h6 className="font-medium text-gray-600 text-lg md:text-2xl uppercase mb-8">
          Eric Chow
        </h6>

        <h1 className="font-normal text-gray-900 text-4xl md:text-6xl leading-none mb-16">
          Software Engineer
        </h1>

        <hr className="h-px my-8 w-7/12  border-0 bg-gray-400 mx-auto"></hr>
        <h1
          id="projects"
          className="mt-12 font-normal text-gray-900 text-2xl md:text-3xl leading-none mb-8"
        >
          Projects
        </h1>
      </div>

      <br></br>

      <div className="container max-w-screen-xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="bg-gray-50 px-8 py-10 rounded-md">
            <a href="https://quoridor.gg">
              <h4 className="font-medium text-gray-700 text-lg mb-4">
                quoridor.gg
              </h4>
            </a>
            <p className="font-normal text-gray-500 text-md">
              Simple 1v1 board game on{" "}
              <a
                className="text-blue-500 underline"
                href="https://play.google.com/store/apps/details?id=com.quoridor.gg.Quoridor"
              >
                Android
              </a>{" "}
              and{" "}
              <a
                className="text-blue-500 underline"
                href="https://apps.apple.com/us/app/quoridor-gg/id1633764827"
              >
                iOS
              </a>{" "}
              Built with Unity. Features an online mode and vs computer mode.
            </p>
            <img
              src="/quoridor-gg3.png"
              className="flex justify-center items-center mt-4 m-auto"
              style={{ maxHeight: 250 }}
            ></img>{" "}
          </div>

          <div className="bg-gray-50 px-8 py-10 rounded-md">
            <a href="https://aftermarket.nz">
              <h4 className="font-medium text-gray-700 text-lg mb-4">
                aftermarket.nz
              </h4>
            </a>

            <p className="font-normal text-gray-500 text-md">
              Marketplace for selling .co.nz and .nz domain names
            </p>
            <img
              className="flex justify-center items-center mt-4 m-auto"
              src="/aftermarket-nz.png"
              style={{ maxHeight: 250 }}
            ></img>
          </div>

          <div className="bg-gray-50 px-8 py-10 rounded-md">
            <a href="https://perfectpitch.io">
              <h4 className="font-medium text-gray-700 text-lg mb-4">
                perfectpitch.io
              </h4>
            </a>

            <p className="font-normal text-gray-500 text-md">
              <a
                className="text-blue-500 underline"
                href="https://play.google.com/store/apps/details?id=com.labgg.perfectpitchtrainer"
              >
                Android app
              </a>{" "}
              and{" "}
              <a
                className="text-blue-500 underline"
                href="https://perfectpitch.io/trainer"
              >
                website
              </a>{" "}
              for training the ability to recognise musical notes by ear. Built
              using Android studio
            </p>
            <img
              src="https://lh3.googleusercontent.com/y9n9az0bnKrcmSQ0RQCMOduqaLgUhqMASs9Jk0_9OcpGXQ5GYTTIOAzbNmX3R4oK4g"
              className="flex justify-center items-center mt-4 m-auto"
              style={{ maxHeight: 250 }}
            ></img>
          </div>

          <div className="bg-gray-50 px-8 py-10 rounded-md">
            <a href="https://plu.sh">
              <h4 className="font-medium text-gray-700 text-lg mb-4">plu.sh</h4>
            </a>
            <p className="font-normal text-gray-500 text-md">
              A free URL shortening service
            </p>
            <img
              src="/plush.png"
              className="flex justify-center items-center mt-4 m-auto"
              style={{ maxHeight: 250 }}
            ></img>
          </div>
        </div>

        <div className="text-center md:mt-32 mt-16">
          <hr className="h-px my-8 w-7/12  border-0 bg-gray-400 mx-auto"></hr>
          <h1
            id="projects"
            className="mt-2 font-normal text-gray-900 text-2xl md:text-3xl leading-none mb-8"
          >
            Contact
          </h1>

          <Contact />
        </div>
      </div>
    </div>
  );
}

export default App;
