export default function Contact() {
  return (
    <section className="relative ">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="">
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            {/* <h2 className="mb-4 text-4xl font-extrabold text-gray-900 ">
              Email us
            </h2>
            <p className="text-xl text-gray-600 mb-8" data-aos="zoom-y-out">
              Email us for general queries, feedback, or just to say hi
            </p> */}
            <section className="">
              <div className="py-8 px-4 mx-auto max-w-screen-md">
                <form
                  action="https://formspree.io/f/xkndkawg"
                  method="POST"
                  className="space-y-8"
                >
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 ">
                      Your email
                    </label>
                    <input
                      name="email"
                      type="email"
                      id="email"
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
                      placeholder="example@gmail.com"
                      required
                    />
                  </div>
                  <div className="sm:col-span-2">
                    <label className="block mb-2 text-sm font-medium text-gray-900 ">
                      Your message
                    </label>
                    <textarea
                      name="message"
                      id="message"
                      rows={6}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
                      placeholder="Leave a comment..."
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="py-3 bg-blue-600 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary-700 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300"
                  >
                    Send message
                  </button>
                </form>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
}
